<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.results.title')" :description="$t('headers.results.description')"/>

    <!-- Desktop results -->
    <div v-if="$vuetify.breakpoint.width > 780"
         class="d-flex flex-nowrap flex-column justify-center align-center ma-4">
      <div v-for="r in results" :key="r.gid" @click="openGame(r.gid, r.event)"
           class="results-row d-flex flex-row flex-nowrap justify-center align-center pa-1">
        <v-icon size="60" color="primary" class="flex-shrink-1 mx-3">{{ '$i-' + r.event }}</v-icon>
        <div v-if="r.official" class="results-row-label flex-shrink-1">
          <v-icon color="yellow" size="26"
                  v-if="$store.state.profile.events[r.event].score === r.score && $store.state.profile.events[r.event].time === r.time">
            mdi-trophy-award
          </v-icon>
          <v-icon v-else :color="'#e0d8d5'" size="26">
            mdi-star-circle-outline
          </v-icon>
        </div>
        <div class="results-row-title flex-grow-1">{{ $t('events.' + r.event) }}</div>
        <div class="results-row-score flex-shrink-1">{{ parseResult(r.score, r.time) }}</div>
        <div class="results-row-timestamp flex-shrink-1">{{ parseTimestamp(r.timestamp) }}</div>
      </div>
    </div>

    <!-- Mobile results -->
    <div v-else class="d-flex flex-nowrap flex-column justify-center align-center ma-4">
      <div v-for="r in results" :key="r.gid" class="results-row pa-2" @click="openGame(r.gid, r.event)">
        <div class="d-flex flex-row flex-nowrap align-center justify-center">
          <v-icon size="60" color="primary" class="flex-shrink-1">{{ '$i-' + r.event }}</v-icon>
          <div class="results-row-title flex-grow-1">{{ $t('events.' + r.event) }}</div>
        </div>
        <div class="d-flex flex-row flex-nowrap align-center justify-center">
          <div v-if="r.official" class="results-row-label-mobile flex-shrink-1">
            <v-icon color="yellow" size="26"
                    v-if="$store.state.profile.events[r.event].score === r.score && $store.state.profile.events[r.event].time === r.time">
              mdi-trophy-award
            </v-icon>
            <v-icon v-else :color="'#e0d8d5'" size="26">
              mdi-star-circle-outline
            </v-icon>
          </div>
          <div class="results-row-score flex-grow-1">{{ parseResult(r.score, r.time) }}</div>
          <div class="results-row-timestamp flex-shrink-1">{{ parseTimestamp(r.timestamp) }}</div>
        </div>
      </div>
    </div>

    <!-- Load more button -->
    <div v-if="more" class="d-flex flex-row flex-nowrap justify-center align-center mb-6">
      <v-btn class="mx-auto justify-center" color="primary" @click="loadMore()">{{ $t('common.more') }}</v-btn>
    </div>

    <!-- Loading -->
    <div v-if="loading" style="width: 100%; text-align: center">
      <v-progress-circular indeterminate color="primary"/>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';

export default {
  name: 'MainResults',
  components: {Header},
  data: () => ({loading: true, results: [], more: false}),
  mounted() {
    if (this.$store.state.myResults.results.length > 0) {
      this.results = this.$store.state.myResults.results;
      this.loading = false;
      this.more = this.results.length >= 50;
    } else {
      this.$api.getMyResults().then((results) => {
        this.results = results;
        this.$store.commit('setMyResults', results);
        this.more = this.results.length === 50;
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    loadMore() {
      this.$api.getMyResults(this.results.length).then((results) => {
        this.results = this.results.concat(results);
        this.more = this.results.length === 50;
        this.$store.commit('setMyResults', this.results);
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    parseResult(score, time) {
      let m = Math.floor(time / 6000);
      let s = Math.floor(time / 100) - m * 60;
      let ms = time - m * 6000 - s * 100;
      let res;
      if (m === 0) res = s + '.' + ms.toString().padStart(2, '0');
      else res = m + ':' + s.toString().padStart(2, '0') + '.' + ms.toString().padStart(2, '0');
      return score + ' ' + this.$t('common.in') + ' ' + res;
    },
    parseTimestamp(timestamp) {
      let date = new Date(timestamp);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      let big = date.getDate() + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear();
      let small = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
      return big + ' ' + small;
    },
    openGame(gid, event) {
      this.$api.getGame(gid).then(() => {
        this.$store.commit('setLocation', '/results');
        this.$router.push('/play/training/' + event);
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    }
  }
}
</script>

<style scoped>
.results-row {
  border: 3px solid #336799;
  border-radius: 16px;
  cursor: pointer;
  height: 80px;
  margin: 5px 10px 15px 10px;
  width: calc(100% - 20px);
}

.results-row-label {
  background-color: #336799;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 40px;
}

.results-row-title {
  color: #336799;
  font-size: 26px;
  font-weight: bold;
  margin-left: 10px;
}

.results-row-score {
  color: #336799;
  font-size: 22px;
  font-weight: bold;
  margin-left: 10px;
  text-align: center;
  width: 170px;
}

.results-row-timestamp {
  color: #336799;
  font-size: 18px;
  margin-right: 10px;
  text-align: center;
  white-space: pre-line;
  width: 140px;
}

.results-row-label-mobile {
  background-color: #336799;
  border-radius: 0 20px 20px 0;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 4px 0 0 -8px;
  width: 40px;
}

@media screen and (max-width: 780px) {
  .results-row {
    height: auto;
  }

  .results-row-score {
    text-align: left;
    width: auto;
  }

  .results-row-timestamp {
    white-space: normal;
    width: auto;
  }
}
</style>
